<template>
  <form class="form" :disabled="disabled" @submit="onSubmit">
    <div
      v-for="({ name, placeholder, type, label }, i) in formFields"
      :key="`${name}-${i}`"
      class="field"
      :class="{
        'field--checkbox': isCheckbox(type)
      }"
    >
      <FormCheckbox
        v-if="isCheckbox(type)"
        :id="getId(name, i)"
        :label="label"
        :name="name"
        checked-value
      />
      <FormText
        v-else
        :id="getId(name, i)"
        :label="label"
        :type="type"
        :name="name"
        :placeholder="placeholder"
      />
    </div>
    <div class="button-wrap">
      <FormError :error-message="formError" />
      <BaseButton
        class="button wildwaffle"
        :color="buttonColor"
        size="l"
        type="submit"
        :disabled="disabled || isLoading"
        :loading="isLoading"
        :text="buttonText"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import { required, email, max } from '@vee-validate/rules'
import { useForm, defineRule } from 'vee-validate'

import { PublicCtaButtonColorEnum } from '@/service/__generated-api'
import { FormInputType } from '@/types/utils'

const { t } = useI18n()

defineRule(
  'required',
  (value: string): boolean | string => required(value) || t('form.required')
)

defineRule(
  'max-length-255',
  (value: string): boolean | string =>
    max(value, [255]) || t('form.incorrect-message')
)

defineRule(
  'email',
  (value: string): boolean | string => email(value) || t('form.incorrect-email')
)

const props = withDefaults(
  defineProps<{
    formFields: FormInputType[]
    id: string
    isLoading?: boolean
    formError?: string
    buttonColor?: PublicCtaButtonColorEnum
    buttonText?: string
    disabled?: boolean
  }>(),
  {
    isLoading: false,
    formError: '',
    buttonColor: 'secondary',
    buttonText: '',
    disabled: false
  }
)

type Schema = {
  [key: string]: string
}

const schema = props.formFields.reduce<Schema>((acc, field) => {
  if (field.rules) acc[field.name] = field.rules
  return acc
}, {})

const emit = defineEmits(['submit'])

const { handleSubmit, resetForm } = useForm({
  validationSchema: schema
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericType = Record<string, any>
const onSubmit = handleSubmit((formData: GenericType) => {
  emit('submit', formData)
  resetForm()
})
const isCheckbox = (type?: string) => type === 'checkbox'
const buttonText = computed(() =>
  props.isLoading ? t('form.submitting') : props.buttonText || t('subscribe')
)

const getId = (fieldName: string, index: number) =>
  `${props.id}-${fieldName}-${index}`
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
}
</style>
