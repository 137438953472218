<template>
  <div
    class="text-input"
    :class="{
      'text-input--invalid': errorMessage
    }"
  >
    <label class="label t-caption-small" :for="id">{{ label }}</label>
    <div class="input-wrapper">
      <input
        :id="id"
        class="input t-caption"
        type="text"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        @input="handleChange"
        @blur="handleBlur"
      />
      <ExclamationBoxIcon
        v-if="errorMessage && meta.dirty"
        aria-hidden="true"
        class="icon icon--24"
        role="img"
      />
      <span v-else aria-hidden="true" class="icon icon--24" />
    </div>

    <FormError v-if="errorMessage" :error-message="errorMessage" />
  </div>
</template>
<script setup lang="ts">
import { useField } from 'vee-validate'

import ExclamationBoxIcon from '@/assets/icons/exclamation-box.svg?component'

const props = withDefaults(
  defineProps<{
    name: string
    label: string
    id: string
    placeholder?: string
  }>(),
  { placeholder: '' }
)

const { handleChange, value, handleBlur, errorMessage, meta } = useField(
  () => props.name
)
</script>

<style scoped lang="scss">
.text-input {
  display: flex;
  flex-direction: column;
  gap: rem(9px);
  width: 100%;
  margin-block-end: rem(16px);
}

.label {
  margin-block-end: rem(6px);
}

.input-wrapper {
  display: flex;
  padding-block: rem(9px);

  border-bottom: 2px solid $black;
  transition:
    color $transition,
    border-color $transition;
}

.input {
  flex-grow: 1;
  color: inherit;

  @include media-up(xl) {
    @include t-label;
  }

  &:-internal-autofill-selected {
    background-color: transparent;
    -webkit-text-fill-color: $black;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: transparent;
    -webkit-text-fill-color: $black;
  }

  &::placeholder {
    color: $black;
    text-transform: uppercase;
    opacity: 0.4;
  }
}
</style>
