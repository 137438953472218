<template>
  <p
    class="form-error t-caption-small"
    :aria-hidden="!errorMessage"
    aria-live="polite"
    :class="[`form-error--align-${align}`, { visible: errorMessage }]"
  >
    <ExclamationIcon v-if="withIcon" class="icon" />
    {{ message }}
  </p>
</template>

<script setup lang="ts">
import ExclamationIcon from '@/assets/icons/exclamation.svg?component'

const props = withDefaults(
  defineProps<{
    errorMessage?: string
    align?: 'right' | 'left'
    withIcon?: boolean
  }>(),
  {
    errorMessage: undefined,
    align: 'right'
  }
)

// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
const message = computed(() => props.errorMessage || ' ')
</script>

<style scoped lang="scss">
.form-error {
  display: flex;
  gap: rem(4px);
  align-items: center;

  opacity: 0;
  transition: opacity $transition;

  &--align-right {
    justify-content: flex-end;
  }

  &.visible {
    opacity: 1;
  }
}
</style>
