<template>
  <div
    class="checkbox"
    :class="{
      'checkbox--invalid': errorMessage
    }"
  >
    <div class="row">
      <input
        :id="id"
        class="input"
        type="checkbox"
        :name="name"
        :value="checkedValue"
        :checked="checked"
        @change="handleChange"
      />
      <label :for="id" class="label">
        <span class="box">
          <CheckIcon class="box__icon" />
        </span>
        <span class="label__text t-caption-small">{{ label }}</span>
      </label>
    </div>
    <FormError
      v-if="errorMessage"
      align="left"
      with-icon
      :error-message="errorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'

import CheckIcon from '@/assets/icons/check.svg?component'

const props = defineProps<{
  label: string
  name: string
  id: string
  checkedValue: boolean
}>()

const { name } = toRefs(props)

const { handleChange, checked, errorMessage } = useField(
  () => props.name,
  undefined,
  {
    type: 'checkbox',
    checkedValue: props.checkedValue
  }
)
</script>

<style scoped lang="scss">
.checkbox {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: rem(6px);
  margin-bottom: rem(18px);
}

.input {
  position: absolute;
  inset: 0;
  pointer-events: none;
  opacity: 0;
}

.label {
  display: flex;
  gap: rem(8px);
  align-items: center;
}

.box {
  @include center-content;
  @include size(24px);
  flex-shrink: 0;
  color: $white;
  border: 2px solid $black;

  transition:
    border-color $transition,
    background-color $transition,
    color $transition;

  &__icon {
    @include size(rem(14px));
    display: block;

    opacity: 0;
    transition: opacity $transition;
  }

  .input:checked + .label &__icon {
    opacity: 1;
  }
}
</style>
